.static {
  width: min(280px, 100%);
  min-height: 187.477px;
  flex-shrink: 0;
  border-radius: 15.515px;
  background: #fff;
  box-shadow: 0px 0px 21.9801082611084px 0px rgba(181, 181, 181, 0.22);
}

.ProfuctsStatic {
  width: min(330px, 100%) !important;
}

.staticContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  gap: 10px;
  padding: 10px;
}

.static > * {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.static {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 17px 16px;
}

.staticsecstatic .rate_s {
  color: red;
  font-size: 12px;
}

.staticsecstatic {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
}

span.Staticsecname {
  color: var(--black-30, #8b8d97);
  text-align: right;
  font-size: 14.101px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.HeaderStatic .icon {
  display: flex;
  width: 46.546px;
  height: 46.546px;
  padding: 10.344px 10.343px 10.343px 10.344px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10.344px;
  background: rgba(255, 204, 145, 0.16);
}
.salesStatic .HeaderStatic .icon {
  background: rgba(85, 112, 241, 0.12);
}
