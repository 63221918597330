* {
  font-family: "Tajawal", sans-serif;
  /* text-align: right; */
  box-sizing: border-box;
  position: relative;
  /* direction: rtl; */
}

.menu-responsive{
  display: none !important;
}

div#root,
.App {
  width: 100%;
  min-height: 100vh;
}

/* Media */
.invoice_actions>* {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-toggle,
.list-close {
  cursor: pointer;
  font-size: 23px;
}

.list-toggle img {
  width: 80px;
}

.list-close {
  display: none;
}

.filter_page,
.filter_page>* {
  flex-wrap: wrap;
}

.ratios_page .ratio_cat {
  width: 199px !important;
}

.date-d {
  flex-wrap: wrap;
}

.side {
  z-index: 1 !important;
}

td img {
  width: 50px;
}

.OrderMode {
  z-index: 999999999999999999999999999999999999999999999999999999999999999999 !important;
}

.inputs_container {
  display: flex;
  align-items: center;
  gap:10px;
  width: 100%;
}

.inputs_container .input_group {
  width: 100%;
}


@media (max-width: 1110px) {

  .list-toggle,
  .list-close {
    display: flex;
  }

  .OrderModel {
    width: 100%;
    height: 100%;
    white-space: break-spaces !important;
    top: 0;
    left: auto !important;
    right: 0 !important;
    translate: 0 !important;
    background: white;
    box-shadow: 1px 0 24px -2px rgb(217, 217, 217);
    border-radius: 4px;
  }

  aside {
    width: 290px !important;
    padding: 30px 10px 19px 10px !important;
    position: fixed !important;
    left:  -200% !important;
    transition: 0.5s ease-in-out;
  }

  .profileMenu .top_info,
  .profileMenu .top_info svg {
    font-size: 14px !important;
  }

  .site-content>div {
    padding: 30px 0 !important;
  }

  span.imgSpan {
    width: 40px !important;
    height: 40px !important;
    font-size: 25px !important;
  }

  header {
    padding-left: 12px !important;
  }

  .menu-responsive{
    display: block !important;
  }

  aside.active {
    left: 0 !important;
    z-index: 99999999999999999999999999999999999999999 !important;
  }

  .site-content {
    /* width: calc(100% - 370px) !important; */
    height: -moz-fit-content !important;
    height: fit-content !important;
    width: 100% !important;
    /* overflow: visible !important; */
  }

  .site-content>div {
    padding: 46px 0px 4px 4px;
    max-width: 100%;
    /* overflow-x: auto; */
    white-space: nowrap;
  }

  .table-t {
    overflow-x: auto;
  }

  .childs {
    padding: 11px !important;
  }

  .common-cl {
    padding: 17px;
    overflow: visible;
  }

  form.formTypeTwo textarea,
  form.formTypeTwo input {
    width: 100% !important;
  }

  form.formTypeTwo>div {
    display: flex;
    flex-direction: column;
    width: 100%;
    white-space: break-spaces;
  }

  /* .table-t {
    width: fit-content !important;
  } */
  .table-tc.table-t {
    width: 100% !important;
  }

  .common-cl {
    width: 100% !important;
  }

  form.formTypeTwo .filefield,
  form.formTypeTwo .inputField label {
    width: 100%;
  }

  .input_file_field_logo.edit_logo_form button {
    width: 100%;
  }

  .input_file_field_logo.edit_logo_form {
    width: 90%;
    padding: 10px;
    box-shadow: 1px 0px 24px -2px rgb(230, 230, 230);
    border-radius: 4px;
    padding: 15px;
    position: fixed;
    background: white;
    z-index: 9999;
    top: 50%;
  }

  .w_btns {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }

  form button {
    width: 90% !important;
  }

  .ratios_page {
    flex-wrap: wrap;
  }

  .filefield,
  .filefield label {
    white-space: wrap;
  }
}

.rgt-columns-manager-popover {
  right: 0 !important;
  left: 50% !important;
}

form#type_1 button {
  width: 100%;
}

.childs {
  padding: 30px;
  width: 100%;
}

table thead th {
  white-space: nowrap;
}

.icons-container {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 7px;
}

.icons-container svg {
  cursor: pointer;
  width: 22px;
  height: 22px;
}

.hidden {
  display: none;
}

.page_title_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal_form {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.modal_form .input_group {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.modal_form .input_group input ,
.modal_form .input_group textarea ,
.modal_form .input_group select {
  border: 1px solid #ccc;
  border-radius: 7px;
  padding: 5px 10px;
  outline: none;
}

.modal_form .input_group input:focus , 
.modal_form .input_group textarea:focus ,
.modal_form .input_group select:focus {
  outline: none;
}


iframe#webpack-dev-server-client-overlay{
  display: none  !important
}